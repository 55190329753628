"use client";
import React, { useEffect, useRef, useState } from "react";
import globalAxiosURL from "@/hooks/globalAxiosURL";
import { useRouter } from "next/navigation";
import { useDispatch } from "react-redux";
import { setFlight } from "@/redux/flightsSlice";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// import SpecialOfferCard from "@/components/flightsHome/SpecialOfferCard";
import SpecialOffersNewCard from "@/components/flightsHome/SpecialOffersNewCard";

const SpecialOffers = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const route = useRouter();
  const [places, setPlaces] = useState([]);
  const [flights, setFlights] = useState([]);
  const axios = globalAxiosURL();
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const [value, setValue] = useState("");
  const handleInputChange = (e) => {
    setValue(e.target.value);
  };
  const handleSetValue = (place) => {
    setValue(place);
    setIsOpen(false);
  };
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const fetchFlights = async () => {
      try {
        const res = await axios.get(`/flights?place=${value}`);
        setFlights(res.data);
        const routeArray = res.data.flatMap((flight) => [
          flight.route.origin.city,
          flight.route.destination.city,
        ]);
        const uniqueRoutes = [...new Set(routeArray)];
        setPlaces(uniqueRoutes);
        // console.log(uniqueRoutes);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchFlights();
  }, [axios, value]);
  const handleFetchValue = (e) => {
    e.preventDefault();
  };
  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickCards = (flight) => {
    dispatch(setFlight(flight));

    const arrivalAirportsArray = [];
    const departureAirportsArray = [];
    const dateArray = [];

    arrivalAirportsArray.push(flight.route.destination.airportCode);
    departureAirportsArray.push(flight.route.origin.airportCode);
    dateArray.push(
      flight.schedules[flight.schedules.length - 1].departureTime.split("T")[0]
    );
    localStorage.setItem("show", true);

    route.push(
      `/searchResult?origin=${departureAirportsArray}&destination=${arrivalAirportsArray}&startDate=${dateArray}`
    );
  };

  return (
    <div data-aos="fade-up" className="mb-16">
      <h3 className="text-center max-w-[950px] w-[90%] md:w-[80%] mx-auto md:text-2xl lg:text-[24px] mb-6">
        Discover the Cheap flights price that the airlines are trying to hide
        from you.{" "}
        <span className="hidden md:inline-block mt-1">
          We're offering you airlines ticket price breaks so you can save cash.
        </span>
      </h3>
      <p className="hidden md:block text-center font-normal text-lg mb-4 lg:mb-0">
        Have a look
      </p>
      <form
        onSubmit={handleFetchValue}
        className="flex justify-center lg:justify-end"
      >
        <div className="relative">
          <div class="md:absolute inset-y-0 start-0 items-center ps-3 pointer-events-none hidden md:flex">
            <svg
              class="w-4 h-4 text-gray-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            ref={inputRef}
            name="place"
            autoComplete="off"
            onFocus={() => setIsOpen(true)}
            value={value}
            onChange={handleInputChange}
            class="w-[300px] py-2 px-5 ps-10 text-sm hidden md:block text-gray-900 border border-gray-300 rounded-lg bg-gray-100 focus:outline-none"
            placeholder="Enter Location"
            required
          />

          <div>
            {isOpen && (
              <ul
                ref={dropdownRef}
                className="absolute top-full w-full max-h-[300px] overflow-y-auto bg-white border shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] z-50 rounded-b-md mt-1"
              >
                {places.map((place, idx) => (
                  <li
                    key={idx}
                    onClick={() => handleSetValue(place)}
                    className="px-3 py-2 border-b hover:bg-gray-100 cursor-default"
                  >
                    {place}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </form>

      {loading && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-12 lg:gap-y-10 mt-2 md:mt-8 w-[95%] mx-auto md:w-full">
          <div className="rounded-lg">
            <Skeleton count={1} height={200} />
            <Skeleton count={1} height={25} className="mt-3" />
          </div>
          <div className="rounded-lg hidden md:block">
            <Skeleton count={1} height={200} />
            <Skeleton count={1} height={25} className="mt-3" />
          </div>
          <div className="rounded-lg hidden md:block">
            <Skeleton count={1} height={200} />
            <Skeleton count={1} height={25} className="mt-3" />
          </div>
        </div>
      )}
      <div className="flex overflow-x-auto py-9 px-4 md:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-5 lg:gap-y-10 mt-2 md:mt-8 w-full mx-auto md:w-full">
        {flights?.map((flight) => (
          <SpecialOffersNewCard
            key={flight._id}
            flight={flight}
            loading={loading}
            handleClickCards={handleClickCards}
          />
        ))}
      </div>
    </div>
  );
};

export default SpecialOffers;
