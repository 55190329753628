import Image from "next/image";
import Link from "next/link";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import plane from "../../../public/plane.png";

const SpecialOffersNewCard = ({ flight, handleClickCards }) => {
  return (
    // <div
    //   className="h-[250px] min-w-[290px] group text-white relative cursor-pointer bg-cover bg-no-repeat rounded-2xl pr-0"
    //   style={{ backgroundImage: `url(/card-bg.png)` }}
    //   onClick={() => handleClickCards(flight)}
    // >
    //   <div className="absolute top-0 left-0 bg-[#009D4D] text-[#faffff] rounded-tl-2xl rounded-br-xl text-[10px] px-4 py-1">
    //     hamsing
    //   </div>
    //   <div className="w-full absolute top-[70px] mx-auto ml-3 md:-ml-3  duration-500 ease-in-out z-50 inline-flex justify-center">
    //     <Image
    //       src={plane}
    //       alt="plane"
    //       width={1000}
    //       height={1000}
    //       className="w-24 md:w-28"
    //     />
    //   </div>
    //   <div className="flex justify-between w-full">
    //     <div className="pl-4 pt-9 z-10">
    //       <h3 className="text-[#a2e72b] italic font-bold text-lg md:text-xl z-20">
    //         {flight.route.origin.city} to{" "}
    //         <span className="capitalize">{flight.route.destination.city}</span>
    //       </h3>
    //       <button className="bg-white p-1 text-xs md:text-sm px-2 rounded-full text-[#94d12a] font-semibold mt-3">
    //         {flight.price.toLocaleString()}/- BDT
    //       </button>
    //       <p className="w-[43%] text-[8px] mt-5">
    //         Discover the Cheap flights price that the airlines are trying to
    //         hide from you. We're offering you airlines ticket price breaks so
    //         you can save cash.
    //       </p>
    //       <div className="mt-[20px] md:mt-[44px] flex gap-2">
    //         <Link
    //           href="https://x.com/fly_hams85343?t=vCpGsdGuHhk2i_UGnwsidg&s=09"
    //           target="_blank"
    //         >
    //           <div className="size-5 rounded-full flex items-center justify-center bg-white text-black border border-[#d1d1d1] border:[#E1E1E1] transition-all duration-200 hover:scale-105">
    //             <FaTwitter size={12} />
    //           </div>
    //         </Link>
    //         <Link href="https://www.facebook.com/hamsfly.om.bd" target="_blank">
    //           <div className="size-5 rounded-full flex items-center justify-center bg-white text-black border border-[#d1d1d1] border:[#E1E1E1] transition-all duration-200 hover:scale-105">
    //             <FaFacebookF size={12} />
    //           </div>
    //         </Link>
    //         <Link
    //           href="https://www.instagram.com/hamsfly1?igsh=MW91cWNwM3RnY2RnOQ=="
    //           target="_blank"
    //         >
    //           <div className="size-5 rounded-full flex items-center justify-center bg-white text-black border border-[#d1d1d1] border:[#E1E1E1] transition-all duration-200 hover:scale-105">
    //             <FaInstagram size={12} />
    //           </div>
    //         </Link>
    //       </div>
    //     </div>
    //     <div className="absolute w-[56%] md:w-[57%] right-0 h-full z-0 overflow-hidden rounded-2xl">
    //       <div className="w-full h-full ">
    //         <Image
    //           src={flight.image}
    //           alt="imgae"
    //           width={1000}
    //           height={1000}
    //           className="w-full h-full object-cover object-left"
    //         />
    //       </div>
    //     </div>
    //   </div>
    //   {/* <h3>Card</h3> */}
    // </div>
    <div
      onClick={() => handleClickCards(flight)}
      className={
        "group max-w-sm overflow-hidden rounded-lg bg-white shadow-md hover:shadow-lg transition-shadow cursor-pointer relative"
      }
    >
      {/* <div className="p-1 px-4 rounded-br-lg bg-green-600 absolute top-0 left-0 z-10 text-white text-xs font-medium">
        Hamsing
      </div> */}
      {/* Image Container */}
      <div className="relative h-52 overflow-hidden">
        <Image
          src={flight.image || "/placeholder.svg?height=200&width=400"}
          alt="Destination Image"
          fill
          className="object-cover transition-transform duration-300 group-hover:scale-105"
          priority
        />
      </div>

      {/* Content */}
      <div className="p-4">
        <div className="flex items-start justify-between gap-4">
          {/* Route and Date */}
          <div>
            <h3 className="font-medium text-gray-900 text-lg">
              {flight.route.origin.city} to {flight.route.destination.city}
            </h3>
            <p className="text-sm text-gray-500 mt-1">
              {new Date(flight.schedules[0].departureTime).toLocaleDateString(
                "en-GB",
                {
                  weekday: "short",
                }
              )}
              ,{" "}
              {new Date(flight.schedules[0].departureTime).toLocaleDateString(
                "en-GB",
                {
                  day: "numeric",
                  month: "long",
                }
              )}
            </p>
          </div>

          {/* Price */}
          <div className="text-xl font-semibold text-green-700">
            BDT {flight.price.toLocaleString()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialOffersNewCard;
